<template>
  <div class="bitts-simple-number-filter">
    <h2>{{ secondaryName }}</h2>
    <div class="bitts-simple-number-filter__slider-nums">
      <div>
        {{ numFormatter(0) }}
      </div>
      <div>
        {{ `${numFormatter(maximum)}+` }}
      </div>
    </div>
    <Slider
      :value="sliderValue"
      :open="true"
      :tip-formatter="sliderTooltipFormatter"
      :max="maximum"
      :min="0"
      :range="true"
      :step="20000"
      @change="onChange"
    />
    <div class="bitts-simple-number__amounts">
      <BittsNumber
        v-model="from"
        :min="0"
        :step="20000"
        :formatter="numFormatter"
        width="128"
        @change="(e) => onNumberChange(e, 'from')"
      />
      <div class="w-auto"> to&nbsp;&nbsp; </div>
      <BittsNumber
        v-model="to"
        :min="20000"
        :step="20000"
        :formatter="numFormatter"
        width="128"
        @change="(e) => onNumberChange(e, 'to')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Nullable } from '@crossbeam/types';

import { Slider } from 'ant-design-vue';
import { computed, ref, watchEffect } from 'vue';

import BittsNumber from '../../BittsNumber/BittsNumber.vue';

const {
  value = [0, 1000000],
  currency = 'USD',
  maximum = 1000000,
  secondaryName = 'Opp Amount',
} = defineProps<{
  value: [number, number];
  currency?: string;
  maximum?: number;
  secondaryName?: string;
}>();

const emit =
  defineEmits<(e: 'range-changed', range: [number, number]) => void>();
const from = ref<number>(value[0]);
const to = ref<number>(value[1]);
const sliderValue = computed<[number, number]>(() => [from.value, to.value]);

function getAmountWithCurrency(
  amount: Nullable<number | undefined>,
  currency: string,
) {
  if (!amount && amount !== 0) return '--';
  const options = {
    style: 'currency' as const,
    currency,
    maximumFractionDigits: 0,
  };
  const currencyFormatter = new Intl.NumberFormat('en-US', options);
  return currencyFormatter.format(amount);
}

function sliderTooltipFormatter(value: number | undefined) {
  if (!value && value !== 0) return '';
  let result = getAmountWithCurrency(value, currency);
  if (value >= maximum) result += ' +';
  return result;
}

function numFormatter(value: number | string) {
  return getAmountWithCurrency(Number(value), currency);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function onChange(range: any) {
  const [rangeFrom, rangeTo] = range;
  to.value = rangeTo >= maximum ? Math.max(rangeTo, to.value) : rangeTo;
  from.value =
    rangeFrom >= maximum ? Math.max(rangeFrom, from.value) : rangeFrom;
  emit('range-changed', [from.value, to.value]);
}
function onNumberChange(num: number, type: string) {
  if (type === 'from') {
    from.value = num;
  } else {
    to.value = num;
  }
  emit('range-changed', [from.value, to.value]);
}

watchEffect(() => {
  from.value = value[0];
  to.value = value[1];
});
</script>

<style lang="pcss" scoped>
.bitts-simple-number-filter {
  @apply m-12 min-w-[368px] flex flex-col gap-12;
}
.bitts-simple-number__amounts {
  @apply flex flex-row items-center justify-between;
}
.bitts-simple-number-filter__slider-nums {
  @apply flex flex-row justify-between text-sm text-neutral-text-strong;
}
h2 {
  @apply font-bold text-neutral-text;
}
:deep(.ant-slider-handle::after) {
  @apply !mt-[-4px] !h-20 !w-20;
  box-shadow: 0 0 0 2px theme(colors.neutral.border) !important;
}

:deep(.ant-slider-handle:hover::after) {
  box-shadow: 0 0 0 2px theme(colors.neutral.border) !important;
  inset-block-start: 0px;
  inset-inline-start: 0px;
}

:deep(.ant-slider-handle:focus::after) {
  box-shadow: 0 0 0 2px theme(colors.neutral.border) !important;
  inset-block-start: 0;
  inset-inline-start: 0px;
}

:deep(.ant-slider) {
  @apply ml-8 mr-16;
}

:deep(.ant-slider-rail) {
  @apply bg-neutral-border;
}

:deep(.ant-slider-track),
:deep(.ant-slider:hover .ant-slider-track) {
  @apply bg-secondary-accent;
}
</style>
